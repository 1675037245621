import axios from "axios";
import EncryptUtility from '../components/utility/js/encrypt-utility'

const authHeader = () => {
  if (EncryptUtility.localStorageDecrypt('Token')) {
    axios.defaults.headers = {
      Authorization: "Bearer " + EncryptUtility.localStorageDecrypt('Token'),
    };
  } else {
    delete axios.defaults.headers.Authorization;
  }
};

export default authHeader