<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <!-- App Starts here -->
    <!-- Content Loaded Under Vue Content based on Routing-->
    <!-- App Header is Placed for All Pages -->
    <v-main>
      <app-header v-if="showNav" />
      <Alert />

      <div v-show="this.$route.path !== '/login'">
        <v-dialog content-class="modal-popup" persistent v-model="timeOutModel">
          <v-card rounded="rounded-xl" raised outlined class="center containerbox overflow-x-hidden">
            <v-card-title>
              <v-row>
                <v-col sm="12" md="12" lg="12"> Still with us? </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col sm="12" md="12" lg="12" class="pa-10 pb-10">
                  Your session will expire in <span class="font-weight-semibold inactiveColor--text">
                    {{ timerCounter }} (s)
                  </span> due to inactivity. Click Continue to extend your session
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col sm="12" md="12" lg="12" class="pa-0 ma-0 pt-5 text-right">
                  <v-btn class="btn-style ma-2" outlined color="primarytext" elevation="1" @click="continueSession">
                    Continue
                  </v-btn>
                  <v-btn class="btn-style ma-2 pl-2" outlined color="secondary" elevation="1" @click="logoutSession">
                    Logout
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>


      <router-view class="pt-10" :key="$route.fullPath"></router-view>
    </v-main>
    <!-- Fixed Footer for all Pages -->
    <v-footer class="pa-2 pt-3" :style="{ background: $vuetify.theme.themes[theme].background }">
      <v-col class="text-center pa-2" md="12" sm="12" lg="12" id="footer">
        &copy;{{ new Date().getFullYear() }} — Powered by
        <strong>Industrialytics </strong>
      </v-col>
    </v-footer>
    <LoaderDialog />
  </v-app>
</template>

<script>
import Header from "./components/header/header";
import LoaderDialog from "./components/common/loader-dialog";
import Alert from "./components/common/alert";
// import TimeOut from "./components/common/time-out";
import BrowserUtility from "./components/utility/js/browser-utility"
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
  APPCODE
} from "./components/utility/js/constants-utility";
import EncryptUtility from "./components/utility/js/encrypt-utility.js";
import Utility from "./shared/utility";
import { mapState } from 'vuex';
import jwt_decode from 'jwt-decode';
import authHeader from '../src/services/auth-header';
export default {
  name: "app",
  data() {
    return {
      localTime: 0,
      expTime: 0,
      currTime: 0,
      appCode: "Web",
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      userActivityRefreshTokenTimeout: null,
      timeOutModel: false,
      timerCounter: 0, //set the idle timer counter 
      userIdle: false, //set if user is idle or not
      localKey: [],
      keyValues: [],
      key: '',
      expiredTime: 0,
      currentTime: 0,
      warningTime: 0,
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    prevRoutePath() {
      return EncryptUtility.localStorageDecrypt('LastRoute') === undefined ? null : JSON.parse(EncryptUtility.localStorageDecrypt('LastRoute'));
    },
    presentRoutePath() {
      return EncryptUtility.localStorageDecrypt('PresentRoute') === undefined ? null : JSON.parse(EncryptUtility.localStorageDecrypt('PresentRoute'));
    },
    ...mapState({
      showNav: state => state.ConfigModule.showNav
    }),
  },
  components: {
    "app-header": Header,
    LoaderDialog,
    Alert,
  },
  created() {
  },
  //Code to Remove Scroll Bar Both Life Cycles Events - mounted, destroyed
  mounted() {

    this.removeLocalStorage();

    this.localTime = EncryptUtility.localStorageDecrypt('JWTExp');
    this.expTime = this.localTime == undefined ? 0 : this.localTime * 1000;
    this.currTime = new Date().getTime();
    console.log("JWT Expiry", this.localTime, "Expiry Time", this.expTime, "Current Time", this.currTime);
    if (this.expTime > this.currTime) {
      this.showNav = true
    }
    else {
      this.showNav = false;
      localStorage.clear();
    }

    //Browser Details
    BrowserUtility.osName();
    BrowserUtility.browserDetails();

    // Get Environment Details
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "auto";
  },
  methods: {
    //User activity checker for session timeout
    //Activating trackers for the page
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
    },
    //Deactivating trackers for the page
    deactivateActivityTracker() {
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
    },

    //Checking users activity every seconds if no activity calls the timeout
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.userActivityRefreshToken();
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); //checks every seconds if user is inactive
      }
    },

    //Reset the user activity or timeout 
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction();
        this.userIdle = true; // User is idle and stop the refresh token
      }, INACTIVE_USER_TIME_THRESHOLD); //Max idle time for user 
    },

    userActivityRefreshToken() {
      clearTimeout(this.userActivityRefreshTokenTimeout);
      this.userActivityRefreshTokenTimeout = setTimeout(() => {
        this.localTime = EncryptUtility.localStorageDecrypt('JWTExp');
        this.expTime = this.localTime == undefined ? 0 : this.localTime * 1000;
        let currentTime = Date.now(); // current time 

        let timeDiff = this.expTime - currentTime;
        if (timeDiff > 2400000 && currentTime < this.expTime) {
          this.refreshToken()
        }
      }, 30000);
    },

    //If user is inactive showing timeout popup for all pages except login
    inactiveUserAction() {
      if (this.$route.path !== '/login') {
        this.timerCounter = 120; // Auto logout countdown
        this.logoutCountDownTimer();
        this.timeOutModel = true;
      }
    },

    //End of activity checker
    continueSession() {
      this.timeOutModel = false;
      this.userIdle = false;
      this.timerCounter = 0;
      this.refreshToken();
    },

    //Logout button click event
    logoutSession() {
      this.timeOutModel = false;
      this.timerCounter = 0;
      this.$vuetify.theme.dark = false;
      Utility.logOut();
    },

    //Timer Method
    logoutCountDownTimer() {
      if (this.timerCounter >= 0) {
        setTimeout(() => {
          this.timerCounter -= 1;
          this.logoutCountDownTimer();
        }, 1000) // Reduce the count every sec
      }
      else if (this.userIdle && this.timerCounter <= 0)  // auto logout if user does not take any action
        this.logoutSession(); //force logout
    },
    //Refresh token for authorization
    refreshToken() {
      let loginModel = {
        UserId: EncryptUtility.localStorageDecrypt('userID'),
        AppCode: APPCODE,
        Token: EncryptUtility.localStorageDecrypt('Token'),
      }
      this.axios
        .post("ap/refresh_token", loginModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = response.data.body;
            EncryptUtility.localStorageEncrypt('Token', responseData.Jwt);
            authHeader(responseData.Jwt);
            this.decoded = jwt_decode(responseData.Jwt);
            EncryptUtility.localStorageEncrypt(
              'JWTExp',
              JSON.stringify(this.decoded.exp),
            );
            this.localTime = EncryptUtility.localStorageDecrypt('JWTExp')
          }
          else {
            //show error
            this.logoutSession(); //force logout
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.logoutSession(); //force logout
        });
    },

    //To get minutes and hours in 2 digit format
    padTo2Digits(num) {
      return String(num).padStart(2, '0');
    },
    //Page level localstorage items clearing
    removeLocalStorage() {
      if (this.prevRoutePath === null)
        return "";
      if (this.presentRoutePath === null)
        return "";

      this.localPrevKey = this.prevRoutePath.slice(1).split("-");
      this.localPresentKey = this.presentRoutePath.slice(1).split("-");
      if (this.localPrevKey.length >= 2) {
        this.keyPrv = this.localPrevKey[0].substring(0, 1).toUpperCase() + this.localPrevKey[1].substring(0, 1).toUpperCase();
      } else {
        this.keyPrv = this.localPrevKey[0].substring(0, 1).toUpperCase();
      }
      if (this.localPresentKey.length >= 2) {
        this.keyPrs = this.localPresentKey[0].substring(0, 1).toUpperCase() + this.localPresentKey[1].substring(0, 1).toUpperCase();
      } else {
        this.keyPrs = this.localPresentKey[0].substring(0, 1).toUpperCase();
      }
      this.keyValues = this.allStorage();
      if (this.keyPrs !== this.keyPrv) {
        for (var key = 0; key < this.allStorage().length; key++) {
          if (this.keyValues[key].substring(0, 2) == this.keyPrv) {
            EncryptUtility.localStorageRemoveItem(this.keyValues[key]);
          }
        }
      }
    },

    allStorage() {
      var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
      while (i--) {
        values.push(EncryptUtility.localStorageDecryptKey(keys[i]));
      }
      return values;
    },
  },




  destroyed: function () {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = null;
  },
  //Calling activity tracker before DOm elements mounts
  beforeMount() {
    this.activateActivityTracker();

  },

  //Deactivating tracker before clearing DOM elements
  beforeDestroy() {
    this.deactivateActivityTracker();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  }
};
</script>

<style scoped>
@import "./assets/styles/common.css";
</style>