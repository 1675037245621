const state = {

    LoaderDialog:{
        visible:false,
        title:''
    },
    Alert:{
        type: '',
        isShow:false,
        message: '' 
    },
    UserInfo:{
        id:1, // needs to change from 0 to 1 once login module is set up
        name:''
    },
    TimeOut:{
        visible:false,
    }
    ,
    showNav:EncryptUtility.localStorageDecrypt('showNav') === undefined || EncryptUtility.localStorageDecrypt('showNav') === null? false : JSON.parse(EncryptUtility.localStorageDecrypt('showNav'))
}
const mutations = {
    LoaderDialog(state, payload) {
        state.LoaderDialog = payload;
    },
    Alert(state, payload) {
        state.Alert = payload;
    },
    UserInfo(state, payload) {
        state.UserInfo = payload;
    },
    TimeOut(state, payload) {
        state.TimeOut = payload;
    },
    showNav(state, payload) {
        state.showNav = payload;
    },
    
}
const actions = {}
const getter = {}
import EncryptUtility from '../../components/utility/js/encrypt-utility';
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getter
}