import Vue from 'vue';
import Vuex from 'vuex';
import ConfigModule from './modules/config-module'
Vue.use(Vuex);


export const store = new Vuex.Store({

    modules: {
        ConfigModule
    }

});