<template>
  <div>
    <v-app-bar color="primary" dense fixed>
      <v-toolbar-title>
        <img height="60" :src="logoImg" alt="RLP" style="vertical-align: middle" id="logoImg" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-row v-if="showGlobalSearch" class="hidden-sm-and-down mt-8 mb-1">
          <!-- Search Paramter -->
          <v-col sm="12" md="12" lg="4">
            <v-select attach outlined class="text-black my-1" dense v-model="searchType" :items="searchTypeList" item-text="search_param"
              item-value="search_code" background-color="white" style="z-index: 99 !important; position: relative;">
            </v-select>
          </v-col>
          <!-- Search Text box -->
          <v-col cols="12" sm="12" md="12" lg="8" class="pr-10">
            <div class="searchInput">
              <v-text-field :loading="showGlobalLoader" color="secondary" class="text-black my-1" append-icon="mdi-magnify" dense outlined background-color="white" v-model="search"
                @input="getSearchData"  @click:clear="search = ''" >
              </v-text-field>
              <div v-if="showDialog" v-bind:class="(this.$vuetify.theme.dark) ? 'darkResultBox' : 'resultBox'">
                <div @click='clearGlobalSearch' class="ml-1">Clear Search</div>
                  <!-- <v-icon color='black' left> mdi-close-circle </v-icon> -->
                <!-- Ship List -->
                <div style="max-height:250px; overflow-y: scroll">
                <ul style="margin:10px; list-style-type: none; overflow-y: auto;">
                  <li v-for="item in searchList" :key="item.id" @mousedown="showItem(item)" style="list-style-type: none !important;">
                    <span>
                      <template>
                        <b># {{ item.order_num }}</b> <br />
                        Order Date: {{ item.order_date }} <br />
                        Created By: {{ item.created_by }}
                        <br />
                        <br />
                      </template>
                    </span>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="text-center" v-if="$route.path !== '/login'">

        <!-- S:For Mobile Hambuger  Do not Remove this code-->
        <!-- menu for search -->
        <span class="hidden-md-and-up">
          <v-menu offset-y :rounded="1" :close-on-content-click="false" :nudge-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon elevation="0" v-bind="attrs" v-on="on">
                <v-icon color="white">mdi-magnify</v-icon>
              </v-btn>
            </template>
            <v-list>
              <!-- 2nd Level Child -->
              <template>
                <v-list class="v-list_mobile">

                  <template class="=py-1">
                    <v-list-item>
                      <v-list-item-title>
                        <v-select outlined class="text-black my-1" dense v-model="searchType" :items="searchTypeList" item-text="search_param"
                          item-value="search_code" background-color="white" style="z-index: 99 !important; position: relative;">
                        </v-select>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item class="=py-1">
                    <v-list-item-title>
                      <v-text-field class="text-black my-1" append-icon="mdi-magnify" dense outlined background-color="white" v-model="search"
                        @input="getSearchData" @click:clear="search = '';showGlobalLoader=false">
                      </v-text-field>
                      <div v-if="showDialog" v-bind:class="(this.$vuetify.theme.dark) ? 'darkResultBox' : 'resultBox'">
                        <!-- Ship List -->
                        <ul style="margin:10px; list-style-type: none;">
                          <li v-for="item in searchList" :key="item.id" @mousedown="showItem(item)" style="list-style-type: none !important;">
                            <span>
                              <template>
                                <b># {{ item.order_num }}</b> <br />
                                Order Date: {{ item.order_date }} <br />
                                Created By: {{ item.created_by }}
                                <br />
                                <br />
                              </template>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </template>
            </v-list>
          </v-menu>
        </span>
        <!-- Menu  -->
        <span class="hidden-md-and-up">
          <v-menu offset-y :rounded="1" :close-on-content-click="false" :nudge-width="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon elevation="0" v-bind="attrs" v-on="on">
                <v-icon color="white">mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-group v-for="([text], index) in btns" :key="index">
                <template v-slot:activator>
                  <v-list-item-title>{{ text }}</v-list-item-title>
                </template>

                <!-- 2nd Level Child -->
                <template v-for="(i, indexOfMenu) in menuKeys.length">
                  <v-list class="v-list_mobile" v-if="menuKeys[index] == indexOfMenu" :key="i">
                    <v-list-group v-for="child in permission[index].Childs" :key="child">
                      <template v-slot:activator>
                        <v-list-item-title>
                          <v-icon color="secondary" small v-text="child.Icon"></v-icon>
                          {{ child.FriendlyName }}
                        </v-list-item-title>
                      </template>
                      <!-- 3rd level -->
                      <v-list-item v-for="Childofchild in child.ChildOfChilds" :key="Childofchild" link>
                        <router-link active-class="active" class="nav-link" :to="Childofchild.Url" exact>
                          <v-list-item-title v-text="Childofchild.FriendlyName"></v-list-item-title>
                        </router-link>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </template>
              </v-list-group>
            </v-list>
          </v-menu>
        </span>
        <!-- E:For Mobile Hambuger -->

        <!-- Menu drop down -->
        <template>
          <!-- S:Menu Buttons -->
          <v-menu offset-y v-for="([text], index) in btns" :key="text" :rounded="1" :close-on-content-click="true" :nudge-width="700"
            :nudge-bottom="5">
            <template v-slot:activator="{ attrs, on }">
              <v-btn @click="showDialog = false; " class="customwhite--text btn-style hidden-sm-and-down" text v-bind="attrs" v-on="on">
                {{ text }}
              </v-btn>
            </template>
            <!-- E:Menu Buttons -->
            <!-- S:List of 1st level Child -->
            <template v-for="(i, im) in menuKeys.length">
              <!-- Index of menu is needed beacuse in Vue.js Indexing starts with 1 -->
              <v-list v-if="menuKeys[index] == im" :key="i" class="web-list">
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="4" class="pa-8" v-for="item in permission[index].Childs" :key="item" link>
                    <v-text>
                      <v-icon color="secondary" small v-text="item.Icon"></v-icon>&nbsp;<v-text class="font-weight-bold secondary--text">
                        {{ item.FriendlyName }}</v-text>
                    </v-text>
                    <!-- E:First Level -->
                    <!-- S:List of second Level Child -->
                    <v-col cols="12" class="pa-1" v-for="child in item.ChildOfChilds" :key="child" link>
                      <v-text class="pl-5">
                        <router-link active-class="active" :to="'/' + child.Url" exact><a class="nav-link">{{
          child.FriendlyName
        }}</a></router-link>
                      </v-text>
                    </v-col>
                    <!-- E:List of Second Lebvl -->
                  </v-col>
                </v-row>
              </v-list>
            </template>
          </v-menu>
        </template>
        <!-- Sea -->

        <!-- Favourite Menu -->
        <v-menu offset-y nudge-bottom="14" min-width="230" class="overflow-y-auto" content-class="user-profile-menu-content">

          <template v-slot:activator="{ on, attrs }">
            <v-fade-transition mode="out-in" color="customwhite--text ml-10">
              <v-btn icon small title="Favorite" v-bind="attrs" v-on="on">
                <v-icon color="secondary">mdi-star</v-icon>
              </v-btn>
            </v-fade-transition>
          </template>
          <template>
            <v-list class="web-list">
              <v-row class="py-4" v-if="favLink.length == 0">
                <v-col cols="12" class="pa-1">
                  <v-text class="pl-5">
                    Please add a page as favorites
                  </v-text>
                </v-col>
              </v-row>
              <v-row class="py-4" v-if="favLink.length > 0">

                <v-col cols="12" class="pa-1" v-for="child in favLink" :key="child.MenuId" link>
                  <v-text class="pl-5">
                    <router-link active-class="active" :to="'/' + child.Url" exact><a class="nav-link">{{
          child.FriendlyName
        }}</a></router-link>
                  </v-text>
                </v-col>
              </v-row>
            </v-list>
          </template>
        </v-menu>

        <!-- Code for dark mode toggle do not remove it -->
        <!-- <v-menu>
          <template v-slot:activator="{on, attrs}">
            <v-fade-transition mode="out-in" color="customwhite--text ml-10">
              <v-btn icon @click="toggle_dark_mode" v-bind="attrs" v-on="on">
                <v-icon>
                  {{
                    $vuetify.theme.dark
                      ? icons.mdiWeatherSunny
                      : icons.mdiWeatherNight
                  }}
                </v-icon>
              </v-btn>
            </v-fade-transition>
          </template>
        </v-menu> -->
        <v-menu offset-y nudge-bottom="14" min-width="230" class="overflow-y-auto" content-class="user-profile-menu-content">
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot> -->
            <v-badge offset-x="12" offset-y="12" class="ms-4">
              <v-avatar size="40px" v-bind="attrs" v-on="on">
                <v-icon dark left large> mdi-account-circle </v-icon>
              </v-avatar>
            </v-badge>
          </template>
          <v-list>
            <div class="pb-3 pt-2">
              <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
                <span class="text--primary font-weight-semibold mb-n1">
                  {{ fname }} {{ lname }}
                </span>
                <small class="text--disabled">{{ email }}</small>
              </div>
            </div>
            <v-divider></v-divider>
            <!-- Profile -->
            <v-list-item link @click="$router.push('/user-profile')">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  mdi-account
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!--Printer Settings-->
            <v-list-item link @click="$router.push('/printer-settings')">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  mdi-printer-settings
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Printer Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!--User Feedback-->
            <v-list-item link @click="$router.push('/report-issue')">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  mdi-comment-alert
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Report Issue / Feedback</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Logout -->
            <v-list-item link @click="logout">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  mdi-logout
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-row>
      <v-col sm="11" md="11" lg="11">
        <v-dialog content-class="modal-popup" persistent v-model="Logout">
          <v-card rounded="rounded-xl" raised outlined class="center containerbox overflow-x-hidden">
            <v-card-title>
              <v-row>
                <v-col sm="12" md="12" lg="12"> Logging out </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col sm="12" md="12" lg="12" class="pa-10 pb-10">
                  Are you sure you want to log out?
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col sm="12" md="12" lg="12" class="pa-0 ma-0 pt-5 text-right">
                  <v-btn class="btn-style ma-2" outlined color="primarytext" elevation="1" @click="noLogout">
                    No, I want to continue..
                  </v-btn>
                  <v-btn class="btn-style ma-2 pl-2" outlined color="secondary" elevation="1" @click="yesLogout">
                    Log out
                  </v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js';
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
import axios from 'axios';
import { bus } from '../../main';
export default {
  computed: {
    environmentHeader() {
      return 'Dev';
    },
  },
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt('userID'),
      fname: '',
      favLink: [],
      lname: '',
      email: '',
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
      searchType: "",
      search: "",
      showDialog: false,
      searchTypeList: [],
      showGlobalSearch: false,
      btns: [],
      menudata: '',
      menuKeys: [],
      searchList: [],
      fav: true,
      menu: false,
      message: false,
      showGlobalLoader:false,
      hints: true,
      logoImg: require('@/assets/images/RLP.png'),
      closeMenu: false,
      userName: 'Ashima',
      Logout: false,
      menuItems: [
        { title: 'Receiving', path: '/centralreceiving', icon: 'mdi-briefcase' },
      ],
      response: {},
    };
  },
  async created() {
    if (this.checkTokenExpiry()) {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.response = await this.getMenuList()
      {
        if (this.response.status == 200 && (this.response.data !== undefined && this.response.data.body !== undefined &&
          this.response.data.body.message !== undefined && this.response.data.body.message !== undefined && this.response.data.body.message !== null)) {
          let menuResponseData = this.response.data.body.message;
          if (menuResponseData !== "" && menuResponseData !== null && menuResponseData !== undefined) {
            this.permission = JSON.parse(menuResponseData).Menus;
            this.permission =this.permission.sort((a,b)=>a.Seq-b.Seq)
            this.favLink = JSON.parse(menuResponseData).Favourites;
            let tempMenuKeys = Object.keys(this.permission);     
            this.menuKeys = tempMenuKeys.map(Number);
            this.permission.forEach((element) => {
              this.btns.push([element.FriendlyName]);
         
            });
          }
        }
      }
      LoaderDialog.visible = false;
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchCatRes = await this.getSearchCategoryList()
      let searchTypeData = JSON.parse(searchCatRes.data.body.message)
      let urlList = EncryptUtility.localStorageDecrypt('Url')?.split(",")
      urlList?.forEach((element) => {
        element = element.replaceAll('"', "")
        if(element=="sales-order" || element=="purchase-order")
          this.showGlobalSearch=true
        searchTypeData?.forEach((ele) => {
          if (ele.url == element)
            this.searchTypeList.push(ele)
        })
      });
    }
    this.searchType = this.searchTypeList[0]?.search_code
    bus.$on('favorite', (data) =>
      this.favLink = JSON.parse(data.message).Favourites)
  },
  methods: {
    async getSearchData() {
      if (this.search) {
        this.showGlobalLoader=true
        this.search = this.search?.toUpperCase();
        if (this.search.length > 2 || !this.search) {
          let searchData = await this.postSearchData()
          
          // if(!searchData?.response){
          //   this.showGlobalLoader=false;
          // }
          if(searchData=="{response:NA}"){
            this.showGlobalLoader=false;
            this.searchList=[];
          }
          else{
          this.searchList = JSON.parse(searchData?.data?.body?.message)
          this.showGlobalLoader=false;
          if (this.searchList) {
            // Converting the Date to MM/DD/YYYY/ HH:mm:ss Format
            this.searchList.forEach((element) => {
              element.order_date = Utility.convertESTToLocal(element.order_date);
            });
            this.showDialog = true
          }
          else {
            this.showDialog = false;
            this.searchList=[]
          }
        }
        }
        else {
          this.showGlobalLoader=false;
          this.showDialog = false;
          this.searchList=[]

        
      
      }
    }
    },
    clearGlobalSearch(){
      this.showDialog = false;
      this.showGlobalLoader=false;
          this.searchList=[];
          this.search="";
    },
    // to show Address
    showItem(item) {
      if (this.searchType == 'SO') {
        this.showGlobalLoader=false
        if (this.$route.path.includes('sales-order-details')) {
          this.$router.push(`/sales-order-details/${btoa(item.order_id.toString())}`, {})
        }
        else {
          this.$router.push(`/sales-order-details/${btoa(item.order_id.toString())}`)
        }
      }
      else {
        this.$router.push(`/purchase-order-details/${btoa(item.order_id.toString())}`)
      }
      this.showDialog = false;
      this.clearGlobalSearch()
    },
    async postSearchData() {
      let req = {
        UserId: this.userId,
        Pass: 0,
        SearchStr: this.search,
        Search_Code: this.searchType,
      }
      try{
      return await axios.post('co/global_search', req)
    }
    catch(error){
      return "{response:NA}"
    }
    },
    async getMenuList() {
      this.userId = EncryptUtility.localStorageDecrypt('userID');
      this.getUserDetails();
      return await axios.get(`ap/user_menu?user_id=${this.userId}`);
    },

    async getSearchCategoryList() {
      return await axios.get(`co/global_search_type?UserId=${this.userId}`);
    },

    //Token expiry check on login loader issue fixes
    checkTokenExpiry() {
      this.localTime = EncryptUtility.localStorageDecrypt('JWTExp');
      this.expTime = this.localTime == undefined ? 0 : this.localTime * 1000;
      this.currTime = new Date().getTime();
      if (this.expTime > this.currTime) {
        return true;
      }
      else {
        localStorage.clear();
        sessionStorage.clear();
        this.$vuetify.theme.dark = false;
        return false;
      }
    },
    //Toggling dark mode
    toggle_dark_mode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    //Logout method
    logout() {
      this.Logout = true;
    },
    yesLogout() {
      this.Logout = false;
      this.$vuetify.theme.dark = false;
      Utility.logOut();
    },
    noLogout() {
      this.Logout = false;
    },
    //To get User basic details
    getUserDetails() {
      this.fname = EncryptUtility.localStorageDecrypt('fname');
      this.lname = EncryptUtility.localStorageDecrypt('lname');
      this.email = EncryptUtility.localStorageDecrypt('email');
    }
  },
  mounted() {


  },
  watch:{
    $route (to, from){
      if(to.path!==from.path)
        this.clearGlobalSearch()
    }
} 
};
</script>
<style>
a {
  text-decoration: none;
  cursor: pointer;
}

.search-type {
  position: relative;
  z-index: 9999 !important;
}

.web-list {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.resultBox ul li {
  padding: 2px;
  list-style-type: circle;

}


.nav-link,
.theme--light.nav-link {
  color: var(--v-navlinks-base) !important;
}

.v-list_mobile {
  height: 200px;
  overflow-y: auto;
}

.mobile-menu {
  top: 20px !important;
}

.searchInput {
  width: 100%;
  position: relative;
}

.search-text-input {
  border-radius: 5;
  padding-left: 10
}

.resultBox {
  margin-top: -25px;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #FAFAFA;
  width: 125%;
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  min-height: 0;
  cursor: pointer;
  z-index: 99;

}

.darkResultBox {
  margin-top: -25px;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: black;
  width: 125%;
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  min-height: 0;
  cursor: pointer;
  z-index: 99;
}

.text-black input {
  color: black !important;
}


.v-select.v-input--dense .v-select__selection--comma {
  color: black !important;
}

/* .nav-link:hover {
  color: #909090 !important;
} */
</style>
