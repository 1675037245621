<template>
  <div style="position: fixed; z-index: 9999; right: 1%; top: 6%" v-if="Alert">
    <v-snackbar
      :color="Alert.type"
      :timeout="5000"
      absolute
      top
      right
      elevation="24"
      v-model="Alert.isShow"
      >{{ Alert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="close"
        >
        <v-icon color="customwhite">mdi-close-circle</v-icon>
        </v-btn>
      </template>
      </v-snackbar
    >
  </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
  name: 'Alert',
  computed: {
    ...mapState({
      Alert: (state) => state.ConfigModule.Alert,
    }),
  },
  methods:{
    close(){
      this.Alert.isShow = false;
    }
  }
};
</script>