<template>
  <div>
    <v-dialog
      v-model="LoaderDialog.visible"
      persistent
      :width="$vuetify.breakpoint.xs ? '75%' : '450px'"
    >
      <v-card rounded="rounded-xl" raised outlined class="center containerbox">
        <v-card-text>
          <v-row>
            <v-col md="12" sm="12" lg="12" class="center mt-4 pb-0">
              <span class="popupLoaderTitle"> {{ LoaderDialog.title }}</span>
            </v-col>
            <v-col md="12" sm="12" lg="12">
              <v-progress-linear
                indeterminate
                rounded
                color="customLightGrey"
                class="mb-0"
                height="16px"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: 'LoaderDialog',
  computed: {
    ...mapState({
      LoaderDialog: (state) => state.ConfigModule.LoaderDialog,
    }),
  },
};
</script>
