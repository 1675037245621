import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
//import Vuelidate from 'vuelidate'
import axios from "axios";
import VueAxios from "vue-axios";
import Utility from "./shared/utility";
import router from "./router/router";
import { store } from "../src/state-management/state-mgmt";
import titleMixin from "./mixins/title-mixin";
import VueCookies from "vue-cookies";
import authHeader from "./services/auth-header";
import jwt_decode from "jwt-decode";
import excel from "vue-excel-export";
import EncryptUtility from "./components/utility/js/encrypt-utility";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify";
import VTiptap from "@peepi/vuetify-tiptap";

import { APPCODE } from "./components/utility/js/constants-utility";
Vue.use(VTiptap);
Vue.config.silent = true;

//Old Logic
if (EncryptUtility.localStorageDecrypt("Token")) {
  authHeader(EncryptUtility.localStorageDecrypt("Token"));
} else {
  authHeader(false);
}

//EncryptUtility.localStorageEncrypt("activeUserSession", true);

axios.interceptors.request.use(
  async function (config) {
    //Stop multiple calls for refresh token
    if (config.url == "ap/refresh_token") {
      return config;
    }

    let localTime = EncryptUtility.localStorageDecrypt("JWTExp") * 1000;
    let warningTime = localTime != 0 ? localTime - 180 * 1000 : 0; // time in milisecs
    let currentTime = Date.now(); // current time

    //Check if Current time is greater or equal to warning (3 mins)
    if (warningTime <= currentTime) {
      if (config.url == "ap/login") {
        return config;
      }

      //Refresh token logic
      let loginModel = {
        UserId: EncryptUtility.localStorageDecrypt("userID"),
        AppCode: APPCODE,
        Token: EncryptUtility.localStorageDecrypt("Token"),
      };
      axios.post("ap/refresh_token", loginModel).then((response) => {
        if (response.status == 200 || response.status == 204) {
          let responseData = response.data.body;
          // Update the token in local storage
          EncryptUtility.localStorageEncrypt("Token", responseData.Jwt);
          authHeader(responseData.Jwt);
          let decoded = jwt_decode(responseData.Jwt);
          // Update the expiry in local expriy
          EncryptUtility.localStorageEncrypt("JWTExp", JSON.stringify(decoded.exp));
          console.log("Refresh token time from main.js", localTime, warningTime, currentTime);
          return config;
        } else {
          Utility.logOut();
        }
      });
    }
    return config;
  },
  async function (error) {
    if (error.response.status === 401) {
      Utility.logOut();
      return axios(error.config);
    }
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(async function (response) {
//   localTime = EncryptUtility.localStorageDecrypt('JWTExp') * 1000
//   // Warning time which is 3 mins less than expiry  time
//   warningTime = localTime != 0 ? localTime - 180 * 1000 : 0
//   // To get the current time

//   return response;
// }, async function (error) {
//   if (error.response.status === 401) {
//     Utility.logOut();

//   }
//   return Promise.reject(error);
// });

export const bus = new Vue();
//Local
//axios.defaults.baseURL = "https://localhost:44361/api";
//UAT
//axios.defaults.baseURL = "https://rlpapiuat.azurewebsites.net/api";
//Prepord
//axios.defaults.baseURL = "https://rlpapipp.azurewebsites.net/api";
//Prod
axios.defaults.baseURL = "https://rlpapi.azurewebsites.net/api";
Vue.config.productionTip = false;
Vue.mixin(titleMixin);
// Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(excel);
// Vue.use(store);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
