import CryptoJS from 'crypto-js';
import {
  PASSWORD_ENCRYPT_KEY,
} from '../../utility/js/constants-utility.js';
export default {
  // use this common funtion encrypt the local storage value
  localStorageEncrypt: function (key, value) {
    const encryptedKey = this.localStorageEncryptKey(key);
    if(value ==null) value=''
    const encryptkey = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const iv1 = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const encrypted = CryptoJS.AES.encrypt(value, encryptkey, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    localStorage.setItem(encryptedKey + '', encrypted + '');
  },
  // use this common funtion encrypt the local storage key
  localStorageEncryptKey: function (key) {
    const encryptkey = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const iv1 = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const encryptedKey = CryptoJS.AES.encrypt(key, encryptkey, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedKey;
  },
  // use this common funtion decrypt the local storage key
  localStorageDecryptKey: function (key) {
    const encryptkey = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const iv1 = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const decText = CryptoJS.AES.decrypt(key, encryptkey, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    if(decText != null || decText != ''){
      return decText.toString(CryptoJS.enc.Utf8);
    }else{
      return '';
    }
  },
  // use this common funtion remove the local storage item
  localStorageRemoveItem: function (key) {
    const encryptedKey = this.localStorageEncryptKey(key);
    localStorage.removeItem(encryptedKey + '');
  },
  //use this common function to decrypt the local storage value
  localStorageDecrypt: function (key) {
    const decryptedKey = this.localStorageEncryptKey(key);
    const secretData = localStorage.getItem(decryptedKey);
    if (secretData != null) {
      const encryptkey = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
      const iv1 = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
      const decText = CryptoJS.AES.decrypt(secretData, encryptkey, {
        keySize: 16,
        iv: iv1,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      if(decText != null || decText != ''){
        return decText.toString(CryptoJS.enc.Utf8);
      }else{
        return '';
      }
    }
  },
  //use this function for password encrypt
  passwordEncrypt: function (plainText) {
    const key = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const iv1 = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted + '';
  },
  //use this function for password decrypt
  passwordDecrypt: function (cipher) {
    const key = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const iv1 = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPT_KEY);
    const plainText = CryptoJS.AES.decrypt(cipher, key, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return plainText.toString(CryptoJS.enc.Utf8);
  },
};
